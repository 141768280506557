/* global SD, GeoPoint, SDGeocode, map */
import * as React from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import { useState } from 'react'
import { NumberChip as Chip } from '../components/Chip';
import Button from '@mui/material/Button';
import Menu from '../components/Menu'
import Slider from '@mui/material/Slider';
import DoodleBoard from '../components/canvas/Doodleboard'
import { Stack } from '@mui/material'
import { East, ZoomIn, ZoomOut, ClearAll, North, South, West } from '@mui/icons-material/';
import { navigate } from "gatsby"
import { useTheme } from "@mui/material";
import { styled } from '@mui/material/styles';
import Layout from "../components/layout";
import Draggable from 'react-draggable';
import Editor from "../components/Editor"
import Ad from '../components/Ad'

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const { useEffect, useRef } = React;

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const Container = styled(Box)(({ theme }) => ({
    alignItems: "center",
    justifyContent: "center",
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up("lg")]: {
        flexDirection: 'column',
    }
}));

const MapLayout = styled(Box)(({ theme }) => ({
    '@media (min-width: 0px)': {
        display: 'none'
    },
    '@media (min-width: 1200px)': {
        display: 'block'
    }
}));

const NotSupported = styled(Box)(({ theme }) => ({
    '@media (min-width: 0px)': {
        display: 'block'
    },
    '@media (min-width: 1200px)': {
        display: 'none'
    }
}));




function parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}





const IndexPage = ({ data, location }) => {
    const params = parseQuery(location.search)
    const hideLogo = params.hideLogo;
    const divRef = useRef(null)
    const uploadFile = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = function (e) {
                const img = new Image();
                img.onload = function () {
                    const canvas = divRef.current;
                    canvas.height = img.height
                    canvas.width = img.width
                    const ctx = canvas.getContext('2d');
                    ctx.clearRect(0, 0, img.width, img.height); // Clear the canvas
                    ctx.drawImage(img, 0, 0, img.width, img.height); // Draw the image
                };
                img.src = e.target.result;

            };
            reader.readAsDataURL(file);
        }
    }

    return (
        <Layout fullWidth={true}>
            <Container>
                <Ad />
            </Container>
            <Container>
                <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                >
                    Upload file
                    <VisuallyHiddenInput type="file" onChange={uploadFile} />
                </Button>
                <Editor>
                    <Draggable>
                        <canvas id="map_canvas" ref={divRef}>

                        </canvas>
                    </Draggable>
                </Editor>
                <NotSupported>
                    <h2> This feature is only available using desktop. You can try our other features.</h2>
                    <Menu />
                </NotSupported>
            </Container ></Layout>)
}

export default IndexPage
